import React, { useState } from 'react';
import './App.css';
import {
  IconButton, Typography, Grid, Paper,
  Box, Menu, MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import olympics from './assets/hiroaka.jpeg';

function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="App">
      <IconButton
        edge="end"
        color="inherit"
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1201, mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component="a" href="https://greatofdeeds.com">Health</MenuItem>
        <MenuItem onClick={handleClose} component="a" href="https://coroebuswm.com">Finance</MenuItem>
        <MenuItem onClick={handleClose} component="a" href="https://coroebusfoundation.org">Philanthropy</MenuItem>
      </Menu>
      <Grid container>
        <Grid item xs={6} sx={{ padding: 0 }}>
          <Paper sx={{ height: '100vh', backgroundSize: 'cover', backgroundImage: `url(${olympics})` }}>
            {/* Image background */}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ p: 2, overflowY: 'scroll', height: '95vh' }}>
            <Typography variant="h5" marginTop={2} align='left'>2024</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Qualified for USA Weightlifting Masters Nationals Male 40-44 years of age -73kg</Typography>
            <Typography variant="h5" align='left'>2023</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Earned USA Weightlifting Level 2 Coaches Certification</Typography>
            <Typography variant="h5" align='left'>2022</Typography>
            <Typography variant="body1" align='left'>Earned Certified in Cybersecurity from (ISC)2</Typography>
            <Typography variant="body1" align='left'>Earned Security+ certification from CompTIA</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Became licensed REALTOR® in the State of N.J.</Typography>
            <Typography variant="h5" align='left'>2022</Typography>
            <Typography variant="body1" align='left' >Earned Corrective Exersice Specialist certification from National Association of Sports Medicine (NASM)</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Earned Tactical Strength and Conditioning Facilitator certification from National Strength and Conditioning Association (NSCA)</Typography>
            <Typography variant="h5" align='left'>2021</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Earned USA Weightlifting Level 1 Coaches certification</Typography>
            <Typography variant="h5" align='left'>2019</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Founded Coroebus Foundation Inc.</Typography>
            <Typography variant="h5" align='left'>2018</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Earned Certified Strength and Conditioning Specialist certification from NSCA</Typography>
            <Typography variant="h5" align='left'>2016</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Coroebus Wealth Management was approved as a participant in the U.S. Small Business Administration 8(a) Program</Typography>
            <Typography variant="h5" align='left'>2015</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Founded lifestyle brand and fitness service great of deeds LLC</Typography>
            <Typography variant="h5" align='left'>2014</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Graduated from Boston University with a Master of Science in Banking and Financial Services Management</Typography>
            <Typography variant="h5" align='left'>2013</Typography>
            <Typography variant="body1" align='left'>Founded Coroebus Wealth Management LLC</Typography>
            <Typography variant="body1" align='left' marginBottom={2}>Earned Chartered Retirement Planning Counselor designation from College for Financial Planning (CFFP)</Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;